/* Styles for the main menu */
.menu {
  /* background-color: #333; */
  display: flex;
  justify-content: center;
  /* padding: 10px 0; */
}

.menu-item {
  position: relative;
  margin: 0 0px; /* Adjust spacing between menu items */
  padding: 0px 0px !important;
}

.menu-item a {
  color: black;
  padding: 10px 0px;
  display: block;
  text-decoration: none;
}

.menu-item:hover .submenu {
  display: block;
}

/* Styles for the submenus */
.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(250, 250, 250);
  min-width: 200px; /* Adjust submenu width as needed */
  /* box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px #00000024,
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);  */
  padding-inline-start: 0;
  width: 100vw;
  margin-top: 0px;
  min-height: 300px;
  border: 1px solid lightgray;
  position: fixed;
  top: 0;
}

.submenu-item {
  position: relative;
}

.submenu-item:hover .sub-submenu {
  display: block;
}

.sub-submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  min-width: 200px; /* Adjust sub-submenu width as needed */
  padding-inline-start: 0px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.sub-submenu-item {
  margin: 10px;
  padding: 0 20px 0 20px;
}

.sub-submenu-item:hover {
  background-color: rgb(227, 237, 253);
}

.treatment-name:hover{
  text-decoration: underline;
}

.highlight {
  background-color: yellow;
  transition: background-color 0.5s ease;
}
